.instruction {
	width: 100%;
	max-width: 1120px;
	margin: 0 auto;
}
.instruction,
.instruction * {
	font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
	font-size: 18px;
	font-weight: 400;
	color: #333333;
}
.instruction h1 {
	font-size: 36px;
	margin-bottom: 20px;
	font-weight: 700;
	margin-top: 50px;
}
.instruction h2 {
	font-size: 26px;
	margin-bottom: 27px;
	margin-top: 35px;
	font-weight: 600;
}

.instruction h3 {
	font-size: 20px;
	margin-bottom: 22px;
	margin-top: 25px;
	font-weight: 600;
}
.instruction p {
	font-size: 18px;
	line-height: 26px;
	margin-bottom: 15px;
}
.instruction img {
	margin: 25px auto;
	width: auto;
	max-width: 100%;
	display: block;
}
.instruction img.small {
	max-width: 150px;
}

.instruction table {
	border: solid 1px #eee;
	padding: 0;
	border-collapse: collapse;
}
.instruction table tr th {
	color: #fff;
	background: #7705BC;
	vertical-align: middle;
	text-align: center;
	padding: 12px;
	font-size: 18px;
}
.instruction table tr td {
	vertical-align: middle;
	text-align: center;
	padding: 12px;
	color: #999999;
	background: #ffffff;
	border-right: solid 1px #eee;
	font-size: 18px;
}
.instruction table tbody tr:nth-child(2n+1) td {
	background: #f6f7fb;
}
.instruction ol, .instruction ul {
	margin-bottom: 15px;
}
.instruction ol {
	padding-left: 18px;
}
.instruction b {
	font-weight: 700;
}
.instruction ul {
	padding-left: 90px;
}
.instruction ul li {
	list-style: none;
	position: relative;
	margin-bottom: 12px;
}
.instruction ol li {
	margin-bottom: 12px;
}
.instruction ul li:before {
	content: "";
	left: -21px;
	top: 9px;
	width: 10px;
	height: 10px;
	background: #7705bc;
	position: absolute;
	border-radius: 50%;
}
.instruction p a,
.instruction table tr td a {
	color: #7705bc;
	font-weight: 700;
	opacity: 1;
	transition: 0.3s;
	text-decoration: none;
}
.instruction table tr td a {
	font-weight: 600;
}
.instruction p a:hover {
	opacity: 0.8;
}
.instruction table.small th,
.instruction table.small td{
	font-size: 14px;
}

.instruction-contents {
	display: flex;
	align-items: flex-start;
}
.instruction-contents__column {
	width: 50%;
}
.instruction-contents__column:last-child {
	padding-left: 15px;
}
.instruction-contents ol {
	padding-left: 0;
	list-style: none;
	counter-reset: li;
}
.instruction-contents li:before {
	counter-increment: li;
	content: counters(li, ".") ". ";
}
.instruction-contents ol li a {
	text-decoration: none;
}
.instruction-contents ol li ol {
	margin: 15px 0;
	padding-left: 50px;
}