.cod-table__count-show {
    margin-bottom: 40px;
}

.cod-table__count-show .ant-select .ant-select-selector {
    border: 1px solid #1F36C7;
    border-radius: 0;
    font-size: 18px;
    line-height: 22px;
    color: #1F36C7;
}

.cod-table .ant-table,
.cod-table .ant-table table,
.cod-table .ant-table .ant-table-container,
.cod-table .ant-table .ant-table-container th.cod-table__col:first-child,
.cod-table .ant-table .ant-table-container th.cod-table__col:last-child {
    border-radius: 0;
}

.cod-table .ant-table th.cod-table__col {
    background: #7705BC;
    color: #fff;
}

.cod-table .ant-table th.cod-table__col .ant-table-filter-trigger {
    color: inherit;
}

.cod-table .ant-table td.cod-table__col {
    font-size: 11px;
    line-height: 13px;
    padding: 10px 12px;
    color: #999999;
}

.cod-table .ant-table .cod-table__row:nth-child(even) td.cod-table__col {
    background: #F6F7FB;   
}

.cod-add__fill .ant-form-item-row  .ant-form-item-label label {
    height: auto;
}

.cod-add__fill label,
.cod-add__fill .ant-form-item-label label {
    font-size: 18px;
    color: #999999;
}