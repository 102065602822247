#workbenchPageContent {
    max-width: 100%;
}

#table_po .section-table__row, #po .section-table--po .section-table__row {
    grid-template-columns: minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px);
    width: 3200px;
}
#table_request .section-table__row {
    grid-template-columns: minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px);
    width: 1600px;
}
#partners_table .section-table__row {
    grid-template-columns: minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px) minmax(200px, 200px);
    width: 100%;
}

.section-table--po .section-table__table {
    overflow-x: scroll;
    max-width: 100%;
}

.section-table__wrap-tables {
    margin: 0;
    margin-left: 0;
}

.po-filters__row {
    gap: 10px
}
.main__po-filters input {
    border: 1px solid hsl(0, 0%, 80%);
    padding: 9px 10px;
}

.main__po-filters .po-filters__btn {
    margin-left: 0;
}

#po-application .message__avatar img {
    max-width: 100%;
}
#requests-doc-2 .section-table--po .section-table__btn {
    align-self: center;
    margin-top: 32px;
}
#requests-doc-confirmation.application-table__wrap-tables, #requests-doc-transport-2.application-table__wrap-tables {
    max-width: 808px;
    margin-bottom: 32px;
}
.form--chat .form__textarea {
    padding: 18px 16px 18px 49px;
}
.form--chat .form__input-file {
    position: absolute;
    top: 14px;
    left: 13px;
    max-width: 25px;
    cursor: pointer;
    z-index: 3;
}
.application-table__wrap-tables .form__input-file {
    position: absolute;
    z-index: 3;
    max-width: 18px;
}
.message__files {
    display: flex;
    flex-direction: column;
}
.message__files .message_file {
    margin-bottom: 10px;
}
.message__files .message_file:last-child {
    margin-bottom: 0;
}
.files-list {
    margin: 15px 0;
}
.file-item {
    margin-bottom: 10px;
}
.file-wrapper {
    display: flex;
    align-items: center;
}
.file-name {
    margin-right: 5px;
}
.remove-file {
    position: relative;
    top: 1px;
}
.application-table__file {
    height: 25px;
}
.form__col.cancel {
    margin-left: auto;
}
.form.form--request.block-tabs__block__form .form__container {
    border-bottom: none;
}
.form__row.btnsForm .form__col {
    width: auto;
}
.btn--disabled {
    background-color: #fff;
    color: #ccc;
    border:none;
    cursor: not-allowed;
}
.btn--disabled:hover {
    background-color: #fff;
    color: #ccc;
    border:none;
    cursor: not-allowed;
}
.application-table__wrap-btns {
    position: relative;
}

.form--confirmation .form__icon {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    fill: #1f36c7;
  }
  
.form--confirmation .form__icon-file {
    width: 18px;
    height: 24px;
    cursor: pointer;
    margin-top: 7.5px;
}

.form--confirmation input[type="file"] {
    position: relative;
    top: -23px;
    z-index: 10;
    cursor: pointer;
}

#home .section-table {
    padding: 25px;
}

#home .section-table .section-table {
    padding-left: 0;
    padding-right: 0;
}

form .form__col {
    display: flex;
    flex-direction: column;
}
form .form__col .label__form__input {
    display: block;
    color: #000;
    font-size: 14px;
    margin-bottom: 7.5px;
}
form .form__col .label__form__input.error {
    color: red;
    font-weight: bold;
}

form .form__row.error {
    margin-bottom: 25px;
}

form .form__row.error {
    margin-bottom: 25px;
}
form span.errorNotice {
    display: block;
    margin-top: 7.5px;
    color: red;
    font-weight: bold;
}
.form--chat .form__row:first-child .form__col:first-child {
    flex-direction: row;
    width: 145px;
}
.info__list {
    flex-wrap: wrap;
}
#posts .btn {
    margin: 0 auto;
}
div[data-automation-id="pageHeader"], div#CommentsWrapper, div[data-automationid="SimpleFooter"] {
    display: none !important;
}
div.CanvasZone > div, div.container, img {
    max-width: 100%;
}

.react-datepicker { font-size: 1em !important }
.react-datepicker__header { padding-top: 0.8em !important}
.react-datepicker__month { margin: 0.4em 1em !important}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em !important; 
  line-height: 1.9em !important;
  margin: 0.166em !important;
}
.react-datepicker__current-month { font-size: 1em !important }
.react-datepicker__navigation {
  top: 5px !important;
  line-height: 1.7em !important;
  border: none !important;
}
.react-datepicker__navigation--previous { border-right-color: transparent !important; left: 1em !important }
.react-datepicker__navigation--next { 
  border-left-color: transparent !important; 
  right: 1em !important; 
  left: 220px !important 
}


.react-datepicker__time-container { width: 6em !important}
.react-datepicker-time__header { font-size: 1em !important }
.react-datepicker-time__header--time { padding-left: 0px !important; padding-right: 0px !important }
.react-datepicker__time-box { 
  margin-left: 0px !important; 
  margin-right: 0px !important; 
  width:100% !important
}
.react-datepicker__time-list { padding: 0 !important; }

.container_kpi {
    position: relative;
     width: 100%;
     height: 0;
     padding-bottom: 56.25%;
 }
 .iframe_kpi {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
 }
main .contacts__container {
	width: 100%;
	max-width: 1120px;
	margin: 0 auto;
}
main .contacts__container,
main .contacts__container * {
	font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
	font-size: 18px;
	font-weight: 400;
	color: #333333;
}
main .contacts__container h1 {
	font-size: 36px;
	margin-bottom: 20px;
	font-weight: 700;
	margin-top: 50px;
}
main .contacts__container h2 {
	font-size: 26px;
	margin-bottom: 27px;
	margin-top: 35px;
	font-weight: 600;
}
main .contacts__container h3 {
	font-size: 20px;
	margin-bottom: 22px;
	margin-top: 25px;
	font-weight: 600;
}
main .contacts__container p {
	font-size: 18px;
	line-height: 26px;
	margin-bottom: 15px;
}
form .form__flex__input {
    display: flex;
    align-items: center;
}
.contacts-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.contact-card {
    padding: 24px 16px;
    display: flex;
    margin-right: 30px;
    flex-direction: column;
    width: 370px;
    height: 205px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
}
.contact-card:nth-child(3n) {
    margin-right: 0;
}
.contact-item.fullName {
    font-size: 14px;
    line-height: 120%;
    color: #999999;
    margin-bottom: 16px;
}
.contact-item.title {
    color: #333333;
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 16px;
    min-height: 50px;
}
.contact-item.phone a, .contact-item.email a {
 text-decoration: none;
 font-size: 14px;
 line-height: 120%;
 color: #7705BC;
}
.contact-item.phone {
    margin-bottom: 5px;
}
.form.contacts__page {
    margin-top: 20px;
    margin-bottom: 24px;
}
.form.contacts__page input {
    max-width: 500px;
    border: 1px solid #1F36C7;
    padding: 8px 16px;
}
.form.contacts__page span img {
    display: block;
    position: relative;
    left: -36px;
}

*,
html *
{
  scrollbar-width: thin !important;
  scrollbar-color: #1F36C7 transparent !important;
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
  height: 6px !important;
  width: 6px !important;
}
*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
  background: transparent !important;
}
*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
  background-color: #1F36C7 !important;
  border-radius: 2.5px !important;
  border: 1.5px solid transparent!important;
}
.contact-item.files {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
}
.contact-item.files > a {
    text-decoration: none;
    font-size: 14px;
    line-height: 120%;
    color: #7705bc;
    margin-bottom: 5;
}
.contact-item.files > a:last-child {
    margin-bottom: 0;
}
.wrapper-link-new-window {
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper-link-new-window > img {
    display: block;
    margin-right: 10px;
    max-width: 20px;
}
body .form--new-partner .form__col {
    height: auto;
}
.section-table__row-left.section-table__row-left--new {
    display: flex;
    align-items: center;
}
#table_po .section-table__wrap-tables {
    justify-content: center;
}
.section-table__row-left--new::before {
    display: none;
}
.section-table__text {
    text-align: center;
}
.form--chat .form__icon {
    background-color: #1F36C7;
    border-radius: 50%;
    display: block;
}
#requests-doc-security .application-table__col:nth-child(2), #requests-doc-security .application-table__col:nth-child(1) {
    max-width: 100%;
}