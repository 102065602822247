.dock-booking .disabled {
    pointer-events: none;
  }

  #dock-booking .header__content.container {
    max-width: 100%;
  }
  
  /* table {
    border-collapse: collapse;
  } */
  
  .dock-booking td, 
  .dock-booking th {
    padding: 1px 7px;
  }

  /* ._selected {
    border: 3px solid #000;
  } */
  
  ._disabled {
    pointer-events: none;
  }

  #dock-booking .main-table tbody tr td {
    color: #000;
  }
  
  #dock-booking .main-table tbody tr td._blocked {
    background-color:#dfdfdf;
    color: #000;
  }
  
  #dock-booking .main-table tbody tr td.blocked-black {
    background-color:#dfdfdf;
    color: #000;
  }
  
  #dock-booking .main-table tbody tr td._reserved {
    /* border: 2px solid yellow; */
    box-shadow: inset 0 0px 0 3px yellow;
    color: #000;
  }
  
  #dock-booking .main-table tbody tr td._reserved-red {
    /* border: 2px solid red; */
    box-shadow: inset 0 0px 0 3px red;
    color: #000;
  }
  
  #dock-booking .main-table tbody tr td._reserved-green {
    /* border: 2px solid green;  */
    box-shadow: inset 0 0px 0 3px green; 
    color: #000;
  }
  
  #dock-booking .main-table tbody tr td:first-child {
    color: #fff;
    min-width: 144px;
  }

  #dock-booking .main-table tbody tr td._drag-cell {
    background: yellow;
  }

  #dock-booking .main-table tbody tr td.selected {
    background: blue;
  }

  #dock-booking .main-table tbody tr td._drag-cell._blocked,
  #dock-booking .main-table tbody tr td._drag-cell._reserved,
  #dock-booking .main-table tbody tr td._drag-cell._reserved-red,
  #dock-booking .main-table tbody tr td._drag-cell._reserved-green {
    background: red;
  }

  #dock-booking .main-table tbody tr td._drag-cell._reserved {
    background: rgb(136, 0, 255);
  }

  #dock-booking .main-table tbody tr td._search-active {
    box-shadow: inset 0 0 20px rgb(136, 0, 255, .6);
  }

  .dock-booking__section-title {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  #dock-booking .main-table {
    max-height: calc(100vh - 250px);
  }

  #dock-booking .main__content {
    max-width: 100%;
  }

  #dock-booking .dock-booking__main {
    max-width: calc(100% - 315px);
  }

  #dock-booking .dock-booking__main {
    width: auto;
  }

  .dock-booking .filters.dock-booking__filters {
    position: sticky;
    top: 0;
    padding: 24px 15px;
    z-index: 200;
    background: #f6f7fb;
    margin: 0 -15px;
  }

  #dock-booking {
    overflow: visible;
  }

  #dock-booking .filters__row {
    align-items: center;
  }

  #dock-booking .main-table tbody tr td:first-child {
    position: sticky;
    left: 0;
    z-index: 5;
    /* padding: 3px 7px; */
  }

  #dock-booking .main-table .main-table__table thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  #dock-booking .main-table .main-table__table thead th:first-child {
    position: sticky !important;
    top: 0;
    left: 0;
  }

  #dock-booking .filters-cards__item {
    flex-direction: column;
  }

  .filters-cards__item .filters-cards__item-number._number {
    /* width: 100%; */
    text-align: left;
  }

  #dock-booking .filters-cards__item-col._name {
    margin-right: 10px;
  }

  .filters-cards__item .filters-cards__item-number._number span {
    text-overflow: ellipsis;
    display: block;
    /* width: 100%; */
    overflow: hidden;
    white-space: nowrap;
  }

  .ellipsis {
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .booking {
    font-family: 'Tahoma', 'monospace';
  }

  ._blocked .booking {
    display: none;
  }

  .dock__name {
    font-size: 12px;
  }

  .ant-dropdown {
    z-index: 1000 !important;
  }

  #dock-booking .filters-info__row {
    margin-bottom: 2px;
  }

  #dock-booking .filters-cards__item-row {
    justify-content: space-between;
  }

  #dock-booking .filters-cards__item-col:first-child {
    width: auto;
    max-width: 999px;
    margin-right: 0;
  }

  #dock-booking .filters-cards__item-col:nth-child(2) {
    width: auto;
    max-width: 999px;
  }

  #dock-booking .filters-cards__item-text {
    text-transform: none;
  }

  #dock-booking .filters-info__name, #dock-booking .filters-info__text {
    width: 50%;
    max-width: 100%;
  }

  #dock-booking .dock-booking__sidebar {
    min-width: 300px;
  }

  #dock-booking .block-tabs__item {
    width: 50%;
  }